import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY, TOAST_TIMING } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import { toast, Notification } from "components/ui";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp && resp.data) {
        const { accessToken } = resp.data;         
        if (resp.type) {
          
          const user = {
            ...resp.data.data,
            userType:resp.type,
            authority: [resp.type],
          };          

          dispatch(setUser(user));
          dispatch(onSignInSuccess({ accessToken }));

          toast.push(
            <Notification closable type="success" duration={TOAST_TIMING}>
              Login success
            </Notification>
          );
        }

        return resp;
      } else {
        toast.push(
          <Notification closable type="danger" duration={TOAST_TIMING}>
            Success! But you didn’t have permission to log in into the system
            for now.
          </Notification>
        );
        return resp;
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.clear();
    document.title = "Baduli music app";
    navigate(appConfig.unAuthenticatedEntryPath);
    toast.push(
      <Notification closable type="success" duration={TOAST_TIMING}>
        Logout successfully
      </Notification>
    );
  };

  const signOut = async () => {
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
