import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";
import {
  FaTruck,
  FaRegMoneyBillAlt,
  FaEye,
  FaUser,
  FaChartLine,
  FaPlay,
  FaCross,
} from "react-icons/fa";
import { IoIosInformationCircle, IoMdCopy } from "react-icons/io";
import {
  IoEarthSharp,
  IoStatsChart,
  IoBarChartSharp,
  IoFolderOpenOutline,
  IoFolder,
  IoMusicalNotes,
  IoPause,
  IoSettings,
} from "react-icons/io5";
import { TiThMenu, TiMap, TiSupport } from "react-icons/ti";
import { BiCategory, BiComment, BiCommentDetail } from "react-icons/bi";
import { MdOutlineFileUpload } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { RiAdvertisementFill } from "react-icons/ri";
import { TbPlaylist } from "react-icons/tb";

export const USER_NAME_BY_USER_TYPE = [
  { label: "Super Admin", value: "SUPERADMIN" },
  { label: "Sub Admin", value: "SUBADMIN" },
];

export const USER_STATUS_OPTIONS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Deactive", value: "BLOCKED" },
];
export const HOME_SCREEN_VISIBILITY = [
  { label: "True", value: true },
  { label: "False", value: false },
];

export const FILTER_OPTIONS = [
  { label: "Category", value: "category" },
  { label: "Artist", value: "artist" },
];

export const ACTION_ICON_SIZE = ".875rem";

export const NAVIGATION_ICON = {
  COMMENTS: <BiCommentDetail />,
  PLAYLIST: <TbPlaylist />,
  SETTINGS: <IoSettings />,
  ADVERTISEMENT: <RiAdvertisementFill />,
  CROSS_BUTTON: <ImCross />,
  PAUSE: <IoPause />,
  PLAY_ICON: <FaPlay />,
  COPY_ICON: <IoMdCopy />,
  SONG: <IoMusicalNotes />,
  FILE_UPLOAD: <MdOutlineFileUpload />,
  SONG_CATEGORY: <BiCategory />,
  MODAL: <FaTruck />,
  ZONES_STATES: <IoEarthSharp />,
  FINANCER: <FaRegMoneyBillAlt />,
  COMPITIORS: <FaEye />,
  APP_DEFAULTS: <TiThMenu />,
  GRAPH: <IoBarChartSharp />,
  MONTHLY_STAT: <IoStatsChart />,
  USER: <FaUser />,
  LEAD: <FaChartLine />,
  ENQUIRY: <IoIosInformationCircle />,
  VISIT_PURPOSE: <IoFolderOpenOutline />,
  CUSTOMER_CATEGORY: <IoFolder />,
  DEALER_SUMMARY: <TiSupport />,
  STATE_PROJECTION_SUMMARY: <TiMap />,
};

export const APP_NAME = "Baduli";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
export const IMAGE_BASE_URL =
  "https://baduli-music.s3.ap-south-1.amazonaws.com/";

export const TABLE_ACTION_KEYS = {
  EDIT: "Edit",
  DELETE: "Delete",
  ADD: "Add",
  VIEW: "View",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  NAVIGATE: "Navigate",
  DOWNLOAD: "Download",
  IMPORT: "Import",
  CHANGE_PASSWORD: "ChangePassword",
  STATUS: "status",
  VIEW_MORE_INFO: "viewMoreInfo",
  EXPORT: "Export",
  ACTIVITY_LOG: "activityLog",
  HOMESCREEN_VISIBLE: "homeScreenVisibility",
};

export const ACTIVE_INACTIVE_STATUS = [
  {
    label: "ACTIVE",
    identifier: 2,
    key: "ACTIVE",
    value: true,
  },
  {
    label: "BLOCKED",
    identifier: 3,
    key: "BLOCKED",
    value: false,
  },
];
export const HOMESCREEN_VISIBILITY_STATUS = [
  {
    label: "True",
    key: true,
    value: true,
  },
  {
    label: "False",
    key: true,
    value: false,
  },
];

export const TOAST_TIMING = 4000;

export const COMMON_DATE_FORMAT = "DD MMMM YYYY hh:mm A";

export const INITIAL_PAGE_COUNT = 10;

export const NO_DATA_FOUND = "-";

export const NAME_COLUMN_SIZE = "";

export const USER_MANAGEMNT_COMMON_SEARCH_INFO = "Search by name";

export const USER_MANAGEMNT_ACTION_CONSTANT = [
  { label: "Edit", key: TABLE_ACTION_KEYS.EDIT, toolTip: "Edit" },
];

export const LEAD_MODAL_ENQUIRED_CONSTANT = [
  {
    label: <FaEye style={{ fontSize: ACTION_ICON_SIZE }} />,
    key: TABLE_ACTION_KEYS.VIEW_MORE_INFO,
    toolTip: "Model Details",
  },
];

export const TOAST_MESSAGE = {
  USER_SAVED: "User saved successfully!",
  USER_UPADTE: "User updated successfully!",
};

export const PAGE_IDENTIFIER = {
  LISTING_HOME_PLAYLIST: "home-playlist-listing",
  LISTING_LIVE_PLAYLIST: "live-playlist-listing",
  LISTING_ADVERTISEMENT: "advertisement-listing",
  LISTING_ARTIST: "artist-listing",
  LISTING_SONG_CATEGORIES: "song-categories-listing",
  LISTING_SONGS: "songs-listing",
  LISTING_STORY_CATEGORIES: "story-categories-listing",
  LISTING_STORY_SUB_CATEGORIES: "story-sub-categories-listing",
  LISTING_STORIES: "stories-listing",
};

export const FILTER_CONSTANT = {
  isSearch: {
    placeholder: USER_MANAGEMNT_COMMON_SEARCH_INFO,
    toolTipText: USER_MANAGEMNT_COMMON_SEARCH_INFO,
  },

 
  isResetButton: {
    label: "Reset",
    icon: <GrPowerReset />,
    // filterKey: { statuses: "", startDate: null, endDate: null },
  },
  isFilterButton: {
    label: "Filter",
    icon: <CiFilter />,
    filters: [
      {
        component: "select",
        options: USER_STATUS_OPTIONS,
        className: "w-full sm:w-full mb-4 md:mb-4 w-40",
        placeholder: "Select status",
        filterKey: "status",
      },
    ],
  },
};
