import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { themeConfig } from 'configs/theme.config'



i18n.use(initReactI18next).init({
    // resources,
    fallbackLng: themeConfig.locale,
    lng: themeConfig.locale,
    interpolation: {
        escapeValue: false 
    }
})



export default i18n