import { COMMON_DATE_FORMAT } from "constants/app.constant";
import { Tag } from "components/ui";
import { HiOutlinePlusCircle } from "react-icons/hi";
import {
  TABLE_ACTION_KEYS,
  USER_NAME_BY_USER_TYPE,
} from "constants/app.constant";
import { getState } from "store";

import { postApi } from "services/CommonService";
import { APIS } from "constants/api.constant";

const USER_STATUS = [
  { id: 2, name: "ACTIVE" },
  { id: 3, name: "BLOCKED" },
];

export const generateDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = `userID_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  }
  localStorage.setItem("device_id", deviceId);
  return deviceId;
};

export const arrayToString = (fieldName) => {
  return (
    <>
      {[fieldName]?.length > 0
        ? [fieldName].map((str, i, arr) => {
            return (
              <div className="capitalize">{`${str} ${
                i !== [fieldName]?.length - 1 ? "," : ""
              }`}</div>
            );
          })
        : "-"}
    </>
  );
};

export const ValidateData = (data) => {
  if (data === null || data === undefined || data === "" || data.length === 0) {
    return false;
  }
  return true;
};

export const getActualDateAndTime = (data) => {
  if (data) {
    const date = new Date(data);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    hours = String(hours).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  } else {
    return null;
  }
};

export const getFormatedDate = (data) => {
  if (data) {
    const date = new Date(data);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year} `;
  } else {
    return null;
  }
};

export const getTagLabelFromObject = (value, object) => {
  const dataObj = object.find((obj) => obj?.value === value);
  return (
    <Tag
      className={`bg-${dataObj?.color}-100 text-${dataObj?.color}-600 dark:bg-${dataObj?.color}-500/20 dark:text-${dataObj?.color}-100 border-0 rounded`}
    >
      {dataObj.label}
    </Tag>
  );
};

export const chnageUserStatus = (currentValue) => {
  let statusObj = USER_STATUS.find((obj, i, arr) => obj.name !== currentValue);
  return {
    status: statusObj.name,
  };
};

export const getAddButtonObj = ({ heading }) => {
  return [
    {
      label: heading,
      key: TABLE_ACTION_KEYS.ADD,
      icon: <HiOutlinePlusCircle />,
    },
  ];
};

export const getLabelFromObject = (array, value) => {
  let obj = array.find((obj, i, arr) => obj?.value === value);
  if (obj === undefined) {
    return "NA";
  } else {
    return obj.label;
  }
};

export const getFirstDayOfCurrMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getLastDayOfCurrMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
};

export const getUserStatus = (value) => {
  return USER_STATUS.find((obj, i) => obj.id === value);
};

export const getStateDistrictFromArray = (array, key) => {
  return array.map((obj, i, arr) => {
    return obj?.[key];
  });
};

export const getDealerFromArray = (array, keys) => {
  return array.map((obj, i, arr) => {
    return `${obj?.[keys[0]]} (${obj?.[keys[1]]})`;
  });
};

export const changeStateId = (states) => {
  return states?.map((obj, i, arr) => {
    return {
      name: obj?.value?.name,
      countryId: obj?.value?.countryId,
      stateId: obj?.value?.id,
    };
  });
};

export const getActiveUser = () => {
  let activeUserobj = USER_NAME_BY_USER_TYPE.find(
    (obj, i, arr) => obj.value === getState().auth?.user?.userType
  );

  return activeUserobj.label;
};

export const formatAmount = (amount) => {
  const nf = new Intl.NumberFormat("en-IN");
  return `₹ ${nf.format(amount)}`;
};


