import React from "react";
import { motion } from "framer-motion";
import { Select, DatePicker } from "components/ui";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { APIS } from "constants/api.constant";
import { getApi } from "services/CommonService";

const MotionFilters = ({
  motionFilters,
  FilterMenu,
  setFilterValue,
  filterValue,
}) => {
  const loadSongsCategoryOptions = (inputValue, resolve) => {
    const params = {
      search: inputValue,
    };
    getApi(APIS.SONG_CATEGORIES_LIST,params).then((result) => {
      
      const filter = result?.data?.songCategoryData?.map((item) => {
        let option = {
          label: item?.name,
          value: item?._id,
        };
    
        return option;
      });
      resolve(filter);
    });
  };

  const loadSongCategory = debounce(loadSongsCategoryOptions, 300);

  const loadSongsArtistOptions = (inputValue, resolve) => {
    const params = {
      search: inputValue,
    };
    getApi(APIS.ARTIST_LIST, params).then((result) => {
      const filter = result?.data?.artistData?.map((item) => {
        let option = {
          label: item?.fullName,
          value: item?._id,
        };
        return option;
      });
      resolve(filter);
    });
  };

  const loadSongsArtist = debounce(loadSongsArtistOptions, 300);

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: !motionFilters ? 0 : 1,
        height: !motionFilters ? 0 : "auto",
        display: "block",
      }}
      transition={{ duration: 0.15 }}
    >
      <div className="mt-4 md:flex gap-4 w-full">
        {FilterMenu?.isFilterButton?.filters?.map((filter, i) => {
          return (
            <div key={i} className={`w-full xs:mb-4 mt-4 sm:mb-4 md:w-1/5`}>
              {!!filter?.label && <label>{filter?.label}</label>}
              {filter.component === "select" && (
                <Select
                  autoComplete="off"
                  size="sm"
                  className={filter.className}
                  placeholder={filter.placeholder}
                  options={filter.options}
                  value={""}
                  onChange={(selectedValue) => {
                    setFilterValue({
                      ...filterValue,
                      [filter.filterKey]: selectedValue,
                    });
                  }}
                />
              )}
              {filter.component === "songCategory-async-select" && (
                <Select
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  //   className={filter.className}
                  value={filterValue?.[filter?.filterKey]}
                  loadOptions={loadSongCategory}
                  componentAs={AsyncSelect}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                  }}
                />
              )}
              {filter.component === "artist-async-select" && (
                <Select
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  //   className={filter.className}
                  value={filterValue?.[filter?.filterKey]}
                  loadOptions={loadSongsArtist}
                  componentAs={AsyncSelect}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                  }}
                />
              )}
              {filter.component === "date-range-picker" && (
                <DatePicker.DatePickerRange
                  size="sm"
                  dateViewCount={2}
                  value={[
                    filterValue?.[filter?.filterKey1],
                    filterValue?.[filter?.filterKey2],
                  ]}
                  placeholder={filter?.placeholder}
                  onChange={(dateArray) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey1]: dateArray[0],
                      [filter?.filterKey2]: dateArray[1],
                    });
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default MotionFilters;
